<template>
  <v-text-field
    :value="value"
    :label="label"
    :rules="[rule]"
    :placeholder="placeholder"
    @input="select"
    v-bind="$attrs"
  ></v-text-field>
</template>
<script>
export default {
  name: 'MacAddressInput',
  props: {
    value: String,
    label: {
      type: String,
      default: 'MAC',
    },
  },
  data() {
    return {
      placeholder: 'xx:xx:xx:xx:xx:xx',
      rule: (value) => {
        if (!value) {
          return true;
        }
        const pattern =
          /^((([a-fA-F0-9][a-fA-F0-9]+[-]){5}|([a-fA-F0-9][a-fA-F0-9]+[:]){5})([a-fA-F0-9][a-fA-F0-9])$)|(^([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]+[.]){2}([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]))$/;
        return pattern.test(value) || 'keine korrekte MAC-Adresse!';
      },
    };
  },
  methods: {
    select($event) {
      this.$emit('input', $event);
    },
  },
};
</script>
