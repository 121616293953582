<template>
  <v-text-field
    :value="value"
    :label="label"
    :rules="[rule]"
    :placeholder="placeholder"
    @input="select"
    v-bind="$attrs"
  ></v-text-field>
</template>
<script>
export default {
  name: 'IpAddressInput',
  props: {
    value: String,
    label: {
      type: String,
      default: 'IP',
    },
  },
  data() {
    return {
      placeholder: 'xxx.xxx.xxx.xxx',
      rule: (value) => {
        if (!value) {
          return true;
        }
        const pattern =
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return pattern.test(value) || 'keine korrekte IPv4-Adresse!';
      },
    };
  },
  methods: {
    select($event) {
      this.$emit('input', $event);
    },
  },
};
</script>
